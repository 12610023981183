import { Search as GETSearch , GetSearchParams } from "@/services/api/alertAutomation.api";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export interface AutomationServiceGet {
  success: boolean;
  data: any
}

interface ParamsSearchAccountError {
  account: string;
  execid: string;
}

export const SearchError = async (
  credentials: ParamsSearchAccountError
): Promise<AutomationServiceGet> => {
  try {
    const SearchParams: GetSearchParams = {
      account: credentials.account,
      execid: credentials.execid,
    };
    const response = await GETSearch(SearchParams);
    return { ...response };
  } catch (e) {
    ShowNotification(
      "Error",
      "Revisa la cuenta y el execid porque no se encontró información.",
      "error"
    );
    return Promise.reject({});
  }
};
