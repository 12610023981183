import { AxiosError } from "axios";
import { httpClient } from "@/services/axios.service";
import { GetSearchParams } from "@/services/models/inputSearchAccount.model";

export interface InputSearchAccountServiceResponse {
  success: boolean;
  data: Array<{
    "code": string,
    "name": string,
    "parent": string | null,
    "container": boolean,
    "created": string, //Date
    "lastLogin": string, //Date
    "lastUsage": string, //Date
    "disabled": boolean,
    "country": string,
}>;
}

export const Search = async (
  GetSearch: GetSearchParams
): Promise<InputSearchAccountServiceResponse> => {
  try {
    const resData = await httpClient.get<InputSearchAccountServiceResponse>(
      `/v2/accounts?q=*${GetSearch.account}*&limit=100`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
