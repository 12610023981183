<template>
  <div class="p-4 alert-box" :class="containerClass">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon
          v-if="theme === 'warning'"
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
        <XCircleIcon
          v-if="theme === 'danger'"
          class="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
        <CheckCircleIcon
          v-if="theme === 'success'"
          class="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p class="text-sm" :class="textClass">
          <slot></slot>
        </p>
        <p v-if="$slots.actions" class="text-sm mt-4" :class="textClass">
          <slot name="actions"></slot>
        </p>
      </div>
      <div v-if="$props.closable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex p-1.5 text-gray-500 hover:text-gray-600 focus:outline-none"
            @click="$emit('close')"
          >
            <span class="sr-only">Dismiss</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ExclamationIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/vue/solid";

export default {
  name: "AlertBox",
  components: {
    ExclamationIcon,
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
  },
  props: {
    theme: {
      type: String,
      default: "sucess",
      validator: function (value) {
        return ["success", "warning", "danger"].includes(value);
      },
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  computed: {
    containerClass() {
      const classes = [];
      classes.push(`alert-box-${this.theme}`);

      if (this.rounded) {
        classes.push("rounded-md");
      } else {
        classes.push("border-l-4 border-yellow-400");
      }

      if (this.theme === "warning") classes.push("bg-yellow-50");
      else if (this.theme === "danger") classes.push("bg-red-50");
      else if (this.theme === "success") classes.push("bg-green-50");

      return classes.join(" ");
    },
    textClass() {
      if (this.theme === "warning") return "text-yellow-700";
      else if (this.theme === "danger") return "text-red-700";
      else if (this.theme === "success") return "text-green-700";
      return "";
    },
  },
};
</script>
