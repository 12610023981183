import {
  Relate as GETRelate,
  DeleteRelationship as DELETERelate,
} from "@/services/api/deleteAccountRelationship.api";
import {
  InfoGetAccountRelationship,
  InfoDeleteAccountRelationship,
} from "@/types/data.type";
import {
  GetDeleteAccountRelationshipParams,
  DeleteDeleteAccountRelationshipParams,
} from "@/services/models/deleteAccountRelationship.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export const Relate = async (
  InfoDeleteAccountRelationship: InfoGetAccountRelationship
): Promise<unknown> => {
  try {
    const DeleteAccountParams: GetDeleteAccountRelationshipParams = {
      account: InfoDeleteAccountRelationship.account,
    };
    return await GETRelate(DeleteAccountParams);
  } catch (e) {
    ShowNotification(
      "Error",
      "No hay relación con la cuenta compartida. Intenta con otra.",
      "error"
    );
    return Promise.reject({});
  }
};

export const Delete = async (
  InfoDeleteAccountRelationship: InfoDeleteAccountRelationship
): Promise<unknown> => {
  try {
    const DeleteAccountParams: DeleteDeleteAccountRelationshipParams = {
      accountOrStoreId: InfoDeleteAccountRelationship.id,
    };
    return await DELETERelate(DeleteAccountParams);
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al intentar eliminar la relación, intente mas tarde",
      "warning"
    );
    return Promise.reject({});
  }
};
