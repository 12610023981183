import {
  Search as GETSearch,
  Duplicate as PostDuplicate,
} from "@/services/api/duplicateAutomation.api";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export interface DuplicateAutomationGet {
  account: string;
  comments: string;
  created: StringDate;
  default_context: Record<string, string>; //Checkear el tipado
  enabled: boolean;
  first_step: string;
  id: string;
  name: string;
  options: { exec_limit: { count: number; every: string } };
  stats: {
    triggered: number;
    exited: number;
    failed: number;
    active: number;
    aborted: number;
    completed: number;
  };
  steps: Array<{
    comments: string;
    data: { delay: { amount: number; unit: string } };
    id: string;
    next_step: string;
    type: string;
  }>;

  tags: Array<string>;
  trigger: string;
  updated: StringDate;
}

export const SearchAutomation = async (
  account: string
): Promise<Array<DuplicateAutomationGet>> => {
  try {
    const response = await GETSearch({ account });
    return response;
  } catch (e) {
    ShowNotification(
      "Error",
      "Revisa la cuenta base porque no encontramos ningún resultado.",
      "error"
    );
    return Promise.reject({});
  }
};

export const PostDuplicateAutomationDestination = async (
  account: string,
  id: string,
  accountDestination: string
): Promise<void> => {
  try {
    await PostDuplicate(account, id, accountDestination);
    return;
  } catch (e) {
    ShowNotification(
      "Error",
      "Revisa la cuenta base porque no encontramos ningún resultado.",
      "error"
    );
    return Promise.reject({});
  }
};
