import { AxiosError } from "axios";
import { httpStopCampaign } from "@/services/axios.service";

export interface GetSearchParams {
  account: string;
  campaignid: string;
}

export const Stop = async (
  ParamsStopCampaign: GetSearchParams
): Promise<void> => {
  try {
    const resData = await httpStopCampaign.post<void>(
      `${ParamsStopCampaign.account}_bulk_${ParamsStopCampaign.campaignid}/cancel`
    );
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
