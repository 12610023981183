import { AxiosError } from "axios";
import { httpClient } from "@/services/axios.service";
import { SuccessAPIOffsetPaging } from "@/services/models/generic.model";

export interface DuplicateAutomationServiceResponse {
  account: string;
  comments: string;
  created: StringDate;
  default_context: Record<string, string>; //Checkear el tipado
  enabled: boolean;
  first_step: string;
  id: string;
  name: string;
  options: { exec_limit: { count: number; every: string } };
  stats: {
    triggered: number;
    exited: number;
    failed: number;
    active: number;
    aborted: number;
    completed: number;
  };
  steps: Array<{
    comments: string;
    data: { delay: { amount: number; unit: string } };
    id: string;
    next_step: string;
    type: string;
  }>;

  tags: Array<string>;
  trigger: string;
  updated: StringDate;
}

export interface GetSearchParams {
  account: string;
}

export const Search = async (
  GetSearch: GetSearchParams
): Promise<Array<DuplicateAutomationServiceResponse>> => {
  try {
    const resData = await httpClient.get<
      SuccessAPIOffsetPaging<Array<DuplicateAutomationServiceResponse>>
    >(`/v2/${GetSearch.account}/automations`);
    return resData.data.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const Duplicate = async (
  account: string,
  id: string,
  accountDestination: string
): Promise<void> => {
  try {
    await httpClient.post<void>(`/v2/${accountDestination}/automations/copy`, {
      id: id,
      account: account,
    });
    return;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
