import { AxiosError } from "axios";
import { httpRefreshIntegration } from "@/services/axios.service";

export interface PostRefreshParams {
  account: string;
  integration: string;
}

export interface PostRefreshParamsTN {
  account: string;
}

export const Refresh = async (
  PostRefreshIntegration: PostRefreshParams
): Promise<void> => {
  try {
    const url = `${PostRefreshIntegration.integration}/${PostRefreshIntegration.account}/refresh`;
    const resData = await httpRefreshIntegration.post<void>(url);
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const RefreshTN = async (
  PostRefreshIntegration: PostRefreshParamsTN
): Promise<void> => {
  try {
    const resData = await httpRefreshIntegration.post<void>(
      `tiendanube/${PostRefreshIntegration.account}/refresh`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
