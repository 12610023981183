import { AxiosError } from "axios";
import { httpClient } from "@/services/axios.service";

export interface AutomationServiceResponse {
  success: boolean;
  data: any;
}

export interface GetSearchParams {
  account: string;
  execid: string;
}

export const Search = async (
  GetSearch: GetSearchParams
): Promise<AutomationServiceResponse> => {
  try {
    const resData = await httpClient.get<AutomationServiceResponse>(
      `/v2/${GetSearch.account}/automationexecutions/${GetSearch.execid}`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
