import { AxiosError } from "axios";
import { httpClient } from "@/services/axios.service";
import { ErrorAPILegacy } from "@/services/models/generic.model";
import { PostLoginRequest , PostLoginResponse } from "@/services/models/auth.model"

export const Login = async (
    LoginRequest: PostLoginRequest
  ): Promise<PostLoginResponse> => {
    try {
      const resData = await httpClient.post<PostLoginResponse>(
        "/v2/login",
        LoginRequest
      );
  
      return resData.data;
    } catch (e) {
      if ((e as AxiosError)?.response) {
        return (e as AxiosError)?.response?.data as PostLoginResponse;
      } else {
        return Promise.reject({} as ErrorAPILegacy);
      }
    }
  };
  