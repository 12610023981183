import { Validate as GETvalidate, ValidateServiceResponse } from "@/services/api/validateDomain.api";
import { InfoValidateDomain } from "@/types/data.type";
import { GetValidateDomainParams } from "@/services/models/validateDomain.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export const Validate = async (
  infoDomain: InfoValidateDomain
): Promise<ValidateServiceResponse> => {
  try {
    // Mapeo
    const ValidateDomainParams: GetValidateDomainParams = {
      mail: infoDomain.mail,
    };
    return await GETvalidate(ValidateDomainParams);
  } catch (e) {
    ShowNotification("Error", "Hubo un error al obtener la información, intenta más tarde", "error");
    return Promise.reject({});
  }
};
