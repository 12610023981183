import { AxiosError } from "axios";
import { httpClient } from "@/services/axios.service";

export const GetPackPlan = async () => {
  try {
    const resData = await httpClient.get(`/v2/pricing/emails`);
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
  }
};

export const GetContactPlan = async () => {
  try {
    const resData = await httpClient.get(`/v2/pricing/contacts`);
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const GetEmailPlan = async () => {
  try {
    const resData = await httpClient.get(`/v2/pricing/emails`);
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
  }
};
