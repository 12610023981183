import {
  Refresh as POSTRefresh,
  RefreshTN as POSTRefreshTN,
  PostRefreshParams,
  PostRefreshParamsTN,
} from "@/services/api/sendRefreshIntegration.api";
// Notification
import { ShowNotification } from "@/helpers/notification.helper";

interface ParamsRefreshIntegration {
  account: string;
  integration: string;
}

interface ParamsRefreshIntegrationTN {
  account: string;
}

export const RefreshIntegration = async (
  credentials: ParamsRefreshIntegration
): Promise<void> => {
  try {
    // Mapeo
    const RefreshParams: PostRefreshParams = {
      account: credentials.account,
      integration: credentials.integration,
    };
    await POSTRefresh(RefreshParams);
    ShowNotification(
      "Actualización con éxito",
      "Tu solicitud se realizó perfectamente (:",
      "valid"
    );
  } catch {
    ShowNotification(
      "Error",
      "Hubo un error al intentar actualizar. Revisa la información completada.",
      "error"
    );
    return Promise.reject({});
  }
};

export const RefreshIntegrationTN = async (
  credentials: ParamsRefreshIntegrationTN
): Promise<void> => {
  try {
    // Mapeo
    const RefreshParamsTN: PostRefreshParamsTN = {
      account: credentials.account,
    };
    await POSTRefreshTN(RefreshParamsTN);
    ShowNotification(
      "Actualización con éxito",
      "Tu solicitud se realizó perfectamente (:",
      "valid"
    );
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al intentar actualizar. Revisa la información completada.",
      "error"
    );
    return Promise.reject({});
  }
};
