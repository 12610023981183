import { AxiosError } from "axios";
import { httpValidateContact } from "@/services/axios.service";
import { GetValidateDomainParams } from "@/services/models/validateDomain.model";

export const Validate = async (
  ParamsInfoValidate: GetValidateDomainParams
): Promise<unknown> => {
  try {
    const resData = await httpValidateContact.get<unknown>(
      `${ParamsInfoValidate.mail}/validate`
    );
    return resData.data;
  } catch (e) {
    console.log(e);
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
