import { AxiosError } from "axios";
import { httpRelateAccount } from "@/services/axios.service";
import {
  GetDeleteAccountRelationshipParams,
  DeleteDeleteAccountRelationshipParams,
} from "@/services/models/deleteAccountRelationship.model";

export const Relate = async (
  InfoAccountRelationship: GetDeleteAccountRelationshipParams
): Promise<unknown> => {
  try {
    const resData = await httpRelateAccount.get<unknown>(
      `${InfoAccountRelationship.account}`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const DeleteRelationship = async (
  InfoAccountRelationship: DeleteDeleteAccountRelationshipParams
): Promise<unknown> => {
  try {
    return await httpRelateAccount.delete<unknown>(
      `${InfoAccountRelationship.accountOrStoreId}`
    );
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
