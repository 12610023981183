import { httpCreateCoupon } from "@/services/axios.service";
import { httpClient } from "@/services/axios.service";

export interface PostCreateCouponParams {
  code: string;
  discount: number;
}

export const CreateCoupon = async (
  CreateCouponParams: PostCreateCouponParams
): Promise<void> => {
  try {
    const headers = {
      "X-Auth-Token": httpClient.defaults.headers.common["X-Auth-Token"],
      "X-Account": "*",
    };
    const resData = await httpCreateCoupon.post<void>(
      "",
      {
        code: CreateCouponParams.code,
        discount: CreateCouponParams.discount,
      },
      { headers }
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
  }
};
