import { BaseCredentials } from "@/types/auth.type";
import { Login as POSTLogin } from "@/services/api/auth.api";
import { Accounts, PRLData } from "@/services/models/auth.model";
import router from "@/router/index.router";
import { useStore } from "@/store/index.store";
import { ActionTypes as configActionTypes } from "@/store/modules/Config/action-types";

//Helpers
import { ShowNotification } from "@/helpers/notification.helper";
import { SetInterceptorLogIn } from "@/helpers/auth.helper";
import {
  CookieTokenName,
  SetCookieToken,
  DeleteCookieToken,
} from "@/helpers/cookies.helper";

const isArrayOfAccounts = (
  accounts: PRLData | Accounts
): accounts is Accounts => {
  return Array.isArray(<Accounts>accounts);
};

export const Login = async (credentials: BaseCredentials): Promise<void> => {
  try {
    const store = useStore();

    const userToken = credentials.user + "!" + credentials.token;

    const credentialsUnified = {
      user: userToken,
      password: credentials.password,
    };

    const res = await POSTLogin(credentialsUnified);
    if (!isArrayOfAccounts(res.data)) {
      SetCookieToken({
        cname: CookieTokenName,
        token: res.data.token,
        exSeconds: res.data.tokenExpiration,
        exMiliseconds: 1000,
      });
      await store.dispatch(configActionTypes.SET_ISLOGGEDIN, true);

      SetInterceptorLogIn(res.data.token);

      router.push({ name: "Home" });

      return Promise.resolve();
    } else {
      ShowNotification(
        "Cuenta invalida",
        "Se necesita una cuenta administrador para poder acceder.",
        "warning"
      );
      return Promise.reject(res.data);
    }
  } catch (e) {
    ShowNotification(
      "Error",
      "Error al ingresar. Revisá tu mail o contraseña.",
      "error"
    );
    return Promise.reject({});
  }
};

export const Logout = async (): Promise<void> => {
  const store = useStore();
  DeleteCookieToken(CookieTokenName);
  await store.dispatch(configActionTypes.SET_ISLOGGEDIN, false);
  router.push({ name: "Login" });
};
