import {
  GetPackPlan,
  GetContactPlan,
  GetEmailPlan,
} from "@/services/api/linkDiscount.api";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";
import { email } from "@vuelidate/validators";

export interface contactsType {
  contactsFrom: number;
  contactsTo: number;
  emails: number;
  price: number;
}

export interface PackAndEmailType {
  emailPrice: number;
  emails: number;
  monthlyEmailPrice: number;
  monthlyPrice: number;
  price: number;
}

export const GetSizePackPlan = async (): Promise<Array<number>> => {
  try {
    const response = await GetPackPlan();
    const responseFilter = response.data.filter(
      (item: PackAndEmailType) => item.price > 0
    );
    return responseFilter.map(function (emails: PackAndEmailType) {
      return emails.emails;
    });
  } catch (e) {
    ShowNotification(
      "Error",
      "No se pudo obtener las opciones para el tamaño del plan.",
      "error"
    );
    return Promise.reject({});
  }
};

export const GetSizeContactPlan = async (): Promise<Array<number>> => {
  try {
    const response = await GetContactPlan();
    return response.data.map(function (contacts: contactsType) {
      return contacts.contactsTo;
    }) as Array<number>;
  } catch (e) {
    ShowNotification(
      "Error",
      "No se pudo obtener las opciones para el tamaño del plan.",
      "error"
    );
    return Promise.reject({});
  }
};

export const GetSizeEmailPlan = async (): Promise<Array<number>> => {
  try {
    const response = await GetEmailPlan();
    response.data.filter(function (item: PackAndEmailType) {
      return item.monthlyPrice > 0;
    });
    return response.data.map(function (emails: PackAndEmailType) {
      return emails.emails;
    });
  } catch (e) {
    ShowNotification(
      "Error",
      "No se pudo obtener las opciones para el tamaño del plan.",
      "error"
    );
    return Promise.reject({});
  }
};
