import { AxiosError } from "axios";
import { httpValidateCategory } from "@/services/axios.service";
import { GetValidateCategoryParams } from "@/services/models/validateCategory.model";

export const Validate = async (
  ParamsInfoValidate: GetValidateCategoryParams
): Promise<unknown> => {
  try {
    const url = `${ParamsInfoValidate.account}/category/${ParamsInfoValidate.id}`;
    const resData = await httpValidateCategory.get<void>(url, {});
    return resData.data;
  } catch (e) {
    console.log(e);
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
