import { Search as GETSearch } from "@/services/api/inputSearchAccount.api";
import { InfoInputSearchAccount } from "@/types/data.type";
import { GetSearchParams } from "@/services/models/inputSearchAccount.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export interface InputSearchAccountServiceGet {
  success: boolean;
  data: Array<{
    "code": string,
    "name": string,
    "parent": string | null,
    "container": boolean,
    "created": string, //Date
    "lastLogin": string, //Date
    "lastUsage": string, //Date
    "disabled": boolean,
    "country": string,
}>;
}

export const GetSearchAccount = async (
  account: InfoInputSearchAccount
): Promise<InputSearchAccountServiceGet> => {
  try {
    const SearchAccounts: GetSearchParams = {
      account: account.account,
    };
    const response = await GETSearch(SearchAccounts);
    return { ...response };
  } catch (e) {
    ShowNotification(
      "Error",
      "No se pudo encontrar las cuentas. Intenta más tarde",
      "warning"
    );
    return Promise.reject({});
  }
};
