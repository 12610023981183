import {
  CreateCoupon as POSTCreateCoupon,
  PostCreateCouponParams as PostCreateCouponParamsAPI,
} from "@/services/api/createCoupon.api";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export interface PostCreateCouponParams {
  code: string;
  discount: number;
}

export const CreateCoupon = async (
  credentials: PostCreateCouponParams
): Promise<void> => {
  try {
    const createCouponParams: PostCreateCouponParamsAPI = {
      code: credentials.code,
      discount: credentials.discount,
    };
    await POSTCreateCoupon(createCouponParams);
    ShowNotification("Cupón Creado", "El cupón fue creado con éxito.", "valid");
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al crear el cupón. Revisa los datos ingresados.",
      "error"
    );
    return Promise.reject({});
  }
};
