import { Validate as GETvalidate } from "@/services/api/validateContact.api";
import { InfoValidateContact } from "@/types/data.type";
import { GetValidateContactParams } from "@/services/models/validateContact.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export const Validate = async (
  infoContact: InfoValidateContact
): Promise<unknown> => {
  try {
    const ValidateContactParams: GetValidateContactParams = {
      mail: infoContact.mail,
    };
    return await GETvalidate(ValidateContactParams);
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al obtener la información, intenta más tarde",
      "error"
    );
    return Promise.reject({});
  }
};
