import { AxiosError } from "axios";
import { httpIntegrations } from "@/services/axios.service";

export interface ResponseOrderInfoService {
  success: boolean;
  data: {
    shipping_store_branch_name: null;
    payment_details: {
      method: string;
      installments: string;
      credit_card_company: null;
    };
    discount: string;
    created_at: string;
    language: string;
    shipping_option_code: string;
    shipping_cost_owner: string;
    number: number;
    shipping_pickup_type: string;
    total: string;
    shipping: string;
    total_usd: string;
    currency: string;
    id: string;
    shipping_address: {
      country: string;
      address: string;
      city: string;
      locality: string;
      created_at: string;
      zipcode: string;
      number: string;
      default: boolean;
      province: string;
      updated_at: string;
      phone: string;
      name: string;
      customs: null;
      id: number;
      floor: string;
    };
    shipping_cost_customer: string;
    payment_status: string;
    is_created: boolean;
    discount_gateway: string;
    token: string;
    shipping_status: string;
    shipping_option: string;
    cancel_reason: null;
    shipping_tracking_url: null;
    subtotal: string;
    discount_coupon: string;
    gateway: string;
    status: string;
    shipping_tracking_number: string;
  };
}

export interface ResponseFullOrderInfoService {
  success: boolean;
  data: {
    cancelled_at: string | null;
    shipping_store_branch_name: string | null;
    contact_phone: string;
    promotional_discount: {
      store_id: number;
      contents: Array<string | null>;
      created_at: string;
      promotions_applied: Array<string | null>;
      total_discount_amount: string;
      id: string | null;
      order_id: string;
    };
    discount: string;
    shipping_max_days: string | null;
    billing_address: string;
    language: string;
    shipping_option_code: string | null;
    gateway_id: string;
    billing_province: string;
    shipping_cost_owner: string | null;
    number: number;
    shipping: string | null;
    billing_floor: string;
    total_usd: string;
    read_at: string | null;
    id: number;
    checkout_enabled: boolean;
    app_id: string | null;
    shipping_cost_customer: string | null;
    gateway_name: string;
    shipped_at: string | null;
    closed_at: string;
    weight: string;
    shipping_pickup_details: string | null;
    billing_locality: string;
    billing_zipcode: string;
    contact_identification: string;
    gateway_link: string;
    shipping_option: string | null;
    shipping_status: string;
    paid_at: string;
    billing_city: string;
    completed_at: {
      date: string;
      timezone: string;
      timezone_type: number;
    };
    shipping_suboption: Array<string | null>;
    subtotal: string;
    shipping_option_reference: string | null;
    storefront: string;
    shipping_tracking_number: string | null;
    status: string;
    note: string;
    shipping_carrier_name: string | null;
    billing_phone: string;
    payment_details: {
      method: string;
      installments: string;
      credit_card_company: string;
    };
    created_at: string;
    owner_note: string | null;
    products: [
      {
        image: {
          updated_at: string;
          src: string;
          product_id: number;
          alt: Array<string | null>;
          created_at: string;
          id: number;
          position: number;
        };
        compare_at_price: string;
        quantity: string;
        free_shipping: boolean;
        weight: string;
        depth: string;
        variant_id: string;
        price: string;
        product_id: number;
        name: string;
        width: string;
        id: number;
        sku: string;
        barcode: string;
        variant_values: Array<string | null>;
        properties: Array<string | null>;
        height: string;
      }
    ];
    shipping_pickup_type: string;
    total: string;
    client_details: {
      browser_ip: string;
      user_agent: string;
    };
    updated_at: string;
    extra: Record<string, unknown>;
    currency: string;
    shipping_address: {
      country: string | null;
      address: string | null;
      city: string | null;
      locality: string | null;
      created_at: string;
      zipcode: string | null;
      number: string | null;
      default: boolean;
      province: string | null;
      updated_at: string;
      phone: string | null;
      name: string | null;
      customs: string | null;
      id: number;
      floor: string | null;
    };
    store_id: string;
    contact_name: string;
    coupon: Array<string | null>;
    payment_status: string;
    billing_country: string;
    discount_gateway: string;
    billing_name: string;
    token: string;
    landing_url: string;
    cancel_reason: string | null;
    shipping_tracking_url: string | null;
    billing_number: string;
    next_action: string;
    attributes: Array<string | null>;
    discount_coupon: string;
    gateway: string;
    shipping_min_days: string | null;
    customer: {
      note: string | null;
      addresses: Array<string | null>;
      billing_phone: string;
      created_at: string;
      billing_address: string;
      first_interaction: string;
      accepts_marketing_updated_at: string;
      billing_province: string;
      identification: string;
      default_address: string | null;
      updated_at: string;
      billing_floor: string;
      accepts_marketing: boolean;
      extra: Record<string, unknown>;
      id: number;
      email: string;
      total_spent: string;
      last_order_id: number;
      billing_country: string;
      active: boolean;
      billing_locality: string;
      billing_zipcode: string;
      billing_name: string;
      billing_city: string;
      phone: string;
      billing_number: string;
      total_spent_currency: string;
      name: string;
    };
  };
}

export interface OrderInfoTypesResponse {
  integration: string;
  account: string;
  orderId: string;
}

export const Search = async (
  GetSearch: OrderInfoTypesResponse
): Promise<ResponseOrderInfoService> => {
  try {
    const resData = await httpIntegrations.get<ResponseOrderInfoService>(
      `${GetSearch.integration}/${GetSearch.account}/orders/${GetSearch.orderId}`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const FullSearch = async (
  GetSearch: OrderInfoTypesResponse
): Promise<ResponseFullOrderInfoService> => {
  try {
    const resData = await httpIntegrations.get<ResponseFullOrderInfoService>(
      `${GetSearch.integration}/${GetSearch.account}/orders/${GetSearch.orderId}/full`
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
  }
};
