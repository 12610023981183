import { AxiosError } from "axios";
import { httpValidateDomain } from "@/services/axios.service";
import { GetValidateDomainParams } from "@/services/models/validateDomain.model"

export interface ValidateServiceResponse {
  success: boolean;
  data: {
    email?: string;
    status?: string;
    spf?: {
      current?: string;
      corrected?: string;
      result?: string;
      details?: string;
    },
    dkim?: {
      result?: string; 
      corrected?: string;
    },
    dmarc?:{
      result?: string;
      current?: string;
    }
  };
}

export const Validate = async (
  ParamsInfoValidate: GetValidateDomainParams
): Promise<ValidateServiceResponse> => {
  try {
    const resData = await httpValidateDomain.get<ValidateServiceResponse>(
     `${ParamsInfoValidate.mail}/validate`,
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
