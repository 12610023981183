import { Validate as GETvalidate } from "@/services/api/validateCategory.api";
import { NewInfoValidateCategory } from "@/types/data.type";
import { GetValidateCategoryParams } from "@/services/models/validateCategory.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export const Validate = async (
  infoCategory: NewInfoValidateCategory
): Promise<unknown> => {
  try {
    const ValidateCategoryParams: GetValidateCategoryParams = {
      account: infoCategory.account.id,
      id: infoCategory.id,
    };
    return await GETvalidate(ValidateCategoryParams);
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al obtener la información, intenta más tarde",
      "error"
    );
    return Promise.reject({});
  }
};
