import {
  Stop as POSTStop,
  GetSearchParams,
} from "@/services/api/stopCampaign.api";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export interface ParamsStopCampaign {
  account: string;
  campaignid: string;
}

export const StopCampaign = async (
  credentials: ParamsStopCampaign
): Promise<void> => {
  try {
    const StopParams: GetSearchParams = {
      account: credentials.account,
      campaignid: credentials.campaignid,
    };
    await POSTStop(StopParams);
    ShowNotification(
      "Campaña Detenida",
      "La campaña fue detenida con éxito.",
      "valid"
    );
  } catch (e) {
    ShowNotification(
      "Error",
      "Revisa la cuenta y el ID de la campaña porque no se encontró nada.",
      "error"
    );
    return Promise.reject({});
  }
};
